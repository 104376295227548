.mainHeader {
    height: 75px;
}

.mainHeader .aligner {
    max-width: 1360px;
    margin: 0 auto;
    height: 100%;
    padding: 0 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
}


.mainHeader .title h1 {
    font-size: 1.5rem;
    text-align: center;
    color: white;
}

.mainHeader .logoHeader{
    display: flex;
    align-items: center;
}

.mainHeader .logoHeader img {
    height: 4rem;
}

.mainHeader .searchForm {
    height: 33px;
    position: relative;
}

.mainHeader .searchForm>form {
    position: absolute;
    top: 0;
    right: 0;
}

.mainHeader .searchForm>form>label {
    position: absolute;
    top: 5px;
    left: 7px;
    z-index: 1;
    color: var(--white);
    font-size: 1rem;
}

.mainHeader .searchForm>form:focus-within>label {
    color: var(--gray-400);
}

.mainHeader .searchForm>form>input[type="text"] {
    width: 164px;
    height: 33px;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    border: none;
    outline: 0;
    font-size: .8rem;
    padding: 5px 5px 4px 32px;
    transition: width ease 0.3s;
    font-weight: 600;
}

.mainHeader .searchForm>form>input[type="text"]::placeholder {
    text-transform: uppercase;
    color: var(--white);
}

.mainHeader .searchForm>form>input[type="text"]:focus {
    width: 220px;
    color: var(--gray-900);
    background-color: var(--white);
}

.mainHeader .searchForm>form>input[type="text"]:focus::placeholder {
    color: var(--gray-400);
}

.mainHeader .searchBtn {
    display: none;
    text-align: right;
}

.mainHeader .searchBtn>button {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--white);
    font-size: 1rem;
}

@media only screen and (max-width: 500px) {
    .mainHeader .title h1 {
        padding: 1rem;
    }
}

@media only screen and (max-width: 750px) {
    .mainHeader {
        height: 100%;
        padding: .5rem 0;
    }

    .mainHeader .menuBtn>button>span {
        display: none;
    }

    .mainHeader .logo img {
        width: 37px;
    }

    .mainHeader .searchForm {
        display: none;
    }

    .mainHeader .searchBtn {
        display: block;
    }
}

.green {
    background-color: var(--green-500);
}

.red {
    background-color: var(--red-500);
}

.blue {
    background-color: var(--blue-500);
}

.yellow {
    background-color: var(--yellow-500);
}