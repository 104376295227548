.topBar {
    background-color: var(--gray-250);
}

.topBar .aligner {
    max-width: 1400px;
    padding: 0 20px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.listenTheRadio:hover{
    background-color: var(--blue-600);
}

.listenTheRadio{
    cursor: pointer;
    background-color: var(--blue-500);
    border: none;
    color: white;
    display: flex;
    gap: .3rem;
    font-size: .9rem;
    align-items: center;
    justify-content: center;
    padding: .4rem .7rem;
    border-radius: 1rem;
    margin: .5rem;
}

.topBar .userLinks {
    list-style: none;
    display: flex;
}

.topBar .userLinks>li {
    position: relative;
    padding: 0 8px;
}

.topBar .userLinks>li:hover>.floatMenu {
    display: block;
}

.topBar .userLinks>li>a {
    font-size: 1rem;
    color: var(--gray-500);
    text-transform: uppercase;
    text-decoration: none;
    padding: 12px 0;
    transition: color 0.3s ease;
}

.topBar .userLinks>li>a>i {
    font-size: 1rem;
}

.topBar .userLinks a.highlight {
    background: linear-gradient(var(--white), var(--gray-300));
    color: var(--gray-600);
    border: 1px solid var(--gray-300);
    padding: 1px 3px 3px 3px;
    border-radius: 3px;
}

.topBar .userLinks>li>a:hover {
    color: var(--blue-700);
}

.topBar .userLinks>li>a.highlight>i {
    margin: 0 3px;
}

.live{
    border: 1px solid;
    padding: .2rem;
    border-radius: 2rem;
    font-size: .8rem;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
}

.socialIcons {
    list-style: none;
    padding: .5rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.socialIcons li:hover a {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.socialIcons li a {
    color: var(--gray-300);
    text-decoration: none;
    border-radius: 50%;
    display: flex;
    width: 100%;
    height: 100%;
}

.socialIcons li a:hover {
    color: var(--white);
}

@media only screen and (max-width: 360px) {
    .topBar .aligner  {
        flex-direction: column;
    }
}