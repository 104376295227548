/* Footer */
.footer {
    color: var(--white);
    padding: 20px 0;
    text-align: center;
}

.footerContainer {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0 20px;
}

.footerSection {
    margin: 10px;
    flex: 1;
}

.footerSection h3 {
    font-size: 1rem;
    margin-bottom: 10px;
}

.footerSection p,
.footerSection ul,
.footerSection a {
    font-size: .9rem;
    margin: .5rem;
    color: var(--white);
    text-decoration: none;
}

.footerSection ul {
    list-style: none;
    padding: 0;
}

.footerSection ul li {
    margin: 5px 0;
}

.footerSection ul li a:hover {
    color: var(--gray-300);
}

.footerBottom {
    margin-top: 20px;
    font-size: .9rem;
    color: var(--white);
}

.logo {
    width: 14rem;
}

.contact{
    display: flex;
    align-items: center;
    gap: .2rem;
    justify-content: center;
}

.socialIcons {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.socialIcons li {
    margin: 0 10px;
}

.socialIcons li a {
    color: var(--white);
    text-decoration: none;
}


/* Responsividade do Footer */
@media (max-width: 768px) {
    .footerContainer {
        flex-direction: column;
    }
}

.green {
    background-color: var(--green-600);
}

.red {
    background-color: var(--red-600);
}

.blue {
    background-color: var(--blue-700);
}

.yellow {
    background-color: var(--yellow-600);
}