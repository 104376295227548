.page {
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content {
  margin: 1rem;
  padding: 0 .5rem .5rem .5rem;
  display: flex;
  justify-content: center;
}

.body {
  width: 100%;
  max-width: 1265px;
}

.section {
  margin-bottom: 2rem;
}

.sectionHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sectionHeader a{
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--blue-500);
}

.newsArea ul {
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  list-style: none;
  width: 100%;
  gap: 1rem;
  overflow: hidden;
  max-width: 100%;
  overflow-x: auto;
  margin: 1rem 0;
}

.column ul {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  list-style: none;
  max-width: 100%;
  width: fit-content;
  justify-content: space-between;
  margin: 1rem 0;
}

.columncard {
  width: 10rem;
  height: 100%;
}

.columncard:hover span {
  color: var(--orange-500);
}

.columncard span {
  font-size: 1rem;
  font-weight: 600;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em;
  max-height: calc(1.5em * 3);
}

.columnAligner {
  /* background-color: #02ADFC; */
  display: flex;
  align-items: center;
  justify-content: center;

}

.columncard img {
  height: 8rem;
  filter: grayscale(100%);
}

.card {
  width: 15rem;
  height: 100%;
}

.card:hover span {
  color: var(--orange-500);
}

.card span {
  font-size: 1rem;
  font-weight: 600;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em;
  max-height: calc(1.5em * 3);
}

.card img {
  width: 100%;
  border-radius: .5rem;
  height: 8rem;
  object-fit: cover;
}

.text h2 {
  font-size: 3rem;
}

.button:hover {
  background-color: #088dca;
}

.button {
  background-color: #02ADFC;
  color: white;
  height: 2.5rem;
  min-width: 7rem;
  font-weight: 600;
  padding: .5rem;
  border: none;
  cursor: pointer;
}

.buttonArea {
  display: flex;
  gap: 1.8rem;
}

@media only screen and (max-width: 450px) {
  .buttonArea {
    flex-direction: column;
    gap: 1rem;
  }

  .aligner {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .text h2 {
    font-size: 2rem;
  }

  .button {
    width: 100%;
  }
}

.banner {
  width: 100%;
  margin-bottom: 2rem;
}

.program {
  display: flex;
  gap: .5rem;
  flex-direction: column;
  margin-bottom: 1rem;
}

.programName span {
  color: #727272;
}

.programHour {
  flex-direction: column;
  display: flex;
}

.programName {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .5rem;
}