.adArea{
  width: 100%;
  display: flex;
  padding: 0 1rem;
}

.adArea img{
  width: 100%;
  height: 9rem;
  object-fit: contain;
}