.divisor{
  width: 10rem;
  height: .5rem;
  display: flex;
  margin: 1rem 0;
}

.green {
  background-color: var(--green-600);
}

.black{
  background-color: var(--black);
}

.red {
  background-color: var(--red-600);
}

.blue {
  background-color: var(--blue-700);
}

.yellow {
  background-color: var(--yellow-600);
}
